
import { defineComponent, onBeforeMount, onMounted } from 'vue'
import { CoursePlanCreateMenuItems, CoursePlanDetailMenuItems } from '../tab-menu-items'
import { useRoute } from 'vue-router'
import { useEducationPlanDetail } from '@/hooks/useEducationPlanDetail'
import { EducationRouteNames } from '@/router/route-names'
export default defineComponent({
  setup () {
    const { params: { planId } } = useRoute()
    const { name: routeName } = useRoute()
    const { educationPlan, loadCoursePlan, loadCareerPlans, loading, careerPlans } = useEducationPlanDetail(planId as string)
    onBeforeMount(() => {
      loading.value = true
    })
    onMounted(async () => {
      try {
        const coursePlanPromise = loadCoursePlan()
        const careerPlansPromise = loadCareerPlans()
        await coursePlanPromise
        await careerPlansPromise
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    })
    return {
      CoursePlanDetailMenuItems,
      CoursePlanCreateMenuItems,
      EducationRouteNames,
      routeName,
      educationPlan,
      careerPlans,
      loading
    }
  }
})
