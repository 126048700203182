import { CareerPlan } from '@/models/academic-definitions/classes/CareerPlan'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { ICareerPlan } from '@/models/academic-definitions/interfaces/CareerPlan'
import { isICoursePlanArray } from '@/models/academic-definitions/interfaces/CoursePlan'
import { Api } from '../api'
import { CRUD } from '../crud'

class CareerPlanApi implements CRUD<CareerPlan> {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  private convertDtoToCareerPlan(dto: ICareerPlan): CareerPlan {
    let courses

    if (isICoursePlanArray(dto.courses)) {
      courses = dto.courses.map(
        (c) =>
          new CoursePlan(
            c.id,
            c.v1Id,
            c.countries,
            c.information,
            c.links,
            c.published,
            c.display,
            c.config,
            c.createdAt,
            c.updatedAt,
            c.__v
          )
      )
    } else {
      courses = dto.courses
    }

    return new CareerPlan(
      dto.id,
      dto.v1Id,
      dto.countries,
      dto.information,
      dto.links,
      dto.published,
      dto.display,
      dto.config,
      courses,
      dto.createdAt,
      dto.updatedAt,
      dto.__v
    )
  }

  public async findAll(): Promise<CareerPlan[]> {
    const data = await Api.get<ICareerPlan[]>(
      `${this.baseURL}/v1/academic-definitions/careerPlan`
    )
    return data.map((dto) => this.convertDtoToCareerPlan(dto))
  }

  public async findById(id: string): Promise<CareerPlan> {
    const data = await Api.get<ICareerPlan>(
      `${this.baseURL}/v1/academic-definitions/careerPlan/${id}`
    )
    return this.convertDtoToCareerPlan(data)
  }

  public async create(careerPlan: Partial<CareerPlan>): Promise<CareerPlan> {
    return await Api.post(
      `${this.baseURL}/v1/academic-definitions/careerPlan/create`,
      careerPlan
    )
  }

  public async update(
    careerPlan: Partial<CareerPlan>,
    id: string
  ): Promise<CareerPlan> {
    return await Api.patch(
      `${this.baseURL}/v1/academic-definitions/careerPlan/${id}`,
      careerPlan
    )
  }

  public async findCareersByCoursePlanId(id: string): Promise<CareerPlan[]> {
    const data = await Api.get<ICareerPlan[]>(
      `${this.baseURL}/v1/academic-definitions/careerPlan/hasCoursePlan/${id}`
    )
    return data.map((dto) => this.convertDtoToCareerPlan(dto))
  }
}

export const CareerPlanAPI = new CareerPlanApi()
