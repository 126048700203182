import { CoursePlan } from './CoursePlan'
import { PlanInformation } from '../interfaces/PlanInformation'
import { PlanLinks } from '../interfaces/PlanLinks'
import { EducationPlan } from './EducationPlan'
import { EducationPlanConfiguration } from '../enums'

export class CareerPlan extends EducationPlan {
  courses: CoursePlan[] | string[];
  constructor (
    id: string,
    v1Id: string,
    countries: string[],
    information: PlanInformation,
    links: PlanLinks,
    published: boolean,
    display: boolean,
    config: EducationPlanConfiguration[],
    courses: CoursePlan[] | string[],
    createdAt: Date,
    updatedAt: Date,
    __v: number
  ) {
    super(
      id,
      v1Id,
      countries,
      information,
      links,
      published,
      display,
      config,
      createdAt,
      updatedAt,
      __v
    )
    this.courses = courses
  }
}
