import { MenuItem } from 'primevue/menuitem'
import { EducationRouteNames } from '@/router/route-names'

export const contentMenuItems: MenuItem[] = [
  {
    label: 'Cursos',
    to: {
      replace: true,
      name: EducationRouteNames.CONTENT_COURSES
    }
  },
  {
    label: 'Carreras',
    to: {
      replace: true,
      name: EducationRouteNames.CONTENT_CAREERS
    }
  }
]

export const CoursePlanDetailMenuItems: MenuItem[] = [
  {
    label: 'Programas',
    to: {
      replace: true,
      name: EducationRouteNames.CONTENT_COURSES_DETAIL_SYLLABUM
    }
  },
  {
    label: 'Información General',
    to: {
      replace: true,
      name: EducationRouteNames.CONTENT_COURSES_DETAIL_GENERAL_INFO
    }
  }
]

export const CareerPlanDetailMenuItems: MenuItem[] = [
  {
    label: 'Información General',
    to: {
      replace: true,
      name: EducationRouteNames.CONTENT_CAREERS_DETAIL_GENERAL_INFO
    }
  }
]
export const CoursePlanCreateMenuItems: MenuItem[] = [
  {
    label: 'Información General',
    to: {
      replace: true,
      name: EducationRouteNames.CONTENT_COURSES_CREATE_ABOUT
    }
  }
]

export const CareerPlanCreateMenuItems: MenuItem[] = [
  {
    label: 'Sobre la carrera',
    to: {
      replace: true,
      name: EducationRouteNames.CONTENT_CAREERS_CREATE_ABOUT
    }
  }
]
