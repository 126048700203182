import { CareerPlanAPI } from '@/api/academic-definitions/career-plan-api'
import { CoursePlanAPI } from '@/api/academic-definitions/course-plan.api'
import { CareerPlan } from '@/models/academic-definitions/classes/CareerPlan'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { ref } from 'vue'

export const useEducationPlanDetail = (planId?: string) => {
  const educationPlan = ref<CoursePlan | CareerPlan | null>(null)
  const coursePlans = ref<CoursePlan[]>([])
  const careerPlans = ref<CareerPlan[]>([])
  const loading = ref(false)

  return {
    educationPlan,
    coursePlans,
    careerPlans,
    loading,
    loadCareerPlans: async () => {
      try {
        careerPlans.value = planId ? await CareerPlanAPI.findCareersByCoursePlanId(planId) : []
      } catch (error) {
        console.log(error)
      }
    },
    loadCoursePlan: async () => {
      try {
        educationPlan.value = planId ? await CoursePlanAPI.findById(planId) : null
      } catch (error) {
        console.log(error)
      }
    },
    loadCareerPlan: async () => {
      try {
        educationPlan.value = planId ? await CareerPlanAPI.findById(planId) : null
      } catch (error) {
        console.log(error)
      }
    },
    loadCoursePlans: async () => {
      try {
        coursePlans.value = await CoursePlanAPI.findAll()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
