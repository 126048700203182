import { IEducationPlan } from './EducationPlan'
import { PlanInformation } from './PlanInformation'
import { PlanLinks } from './PlanLinks'
export interface ICoursePlan extends IEducationPlan {
  id: string
  v1Id: string
  countries: string[]
  information: PlanInformation
  links: PlanLinks
  published: boolean
  createdAt: Date
  updatedAt: Date
  __v: number
}

export function isICoursePlan (el: any | string | ICoursePlan): el is ICoursePlan {
  return typeof el !== 'string' && 'id' in el && 'countries' in el && 'information' in el && 'links' in el && 'published' in el && 'createdAt' in el && 'updatedAt' in el
}

export function isICoursePlanArray (arr: Array<any | string | ICoursePlan>): arr is ICoursePlan[] {
  return arr.every((obj) => obj !== null && isICoursePlan(obj))
}
